<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('sales_configuration.sales_configuration') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.enable_changing_invoice_number" name="check-button" switch>{{ $t('sales_configuration.Enable_Changing_Invoice_Number_For_Each_Invoice') }}</b-form-checkbox>-->
<!--              </div>-->
<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.disable_invoice_items_edit" name="check-button" switch>{{ $t('sales_configuration.Disable_Invoice_Items_Edit') }}</b-form-checkbox>-->
<!--              </div>-->
<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.disable_estimates_module" name="check-button" switch>{{ $t('sales_configuration.Disable_Estimates_Module') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.enable_invoice_manual_statuses" name="check-button" switch>{{ $t('sales_configuration.Enable_Invoice_Manual_Statuses') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.enable_estimate_manual_statuses" name="check-button" switch>{{ $t('sales_configuration.Enable_Estimate_Manual_Statuses') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.disable_shipping_options" name="check-button" switch>{{ $t('sales_configuration.Disable_Shipping_Options') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.enable_maximum_discount" name="check-button" switch>{{ $t('sales_configuration.Enable_Maximum_Discount') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.mark_invoices_as_already_paid_by_default" name="check-button" switch>{{ $t('sales_configuration.Mark_Invoices_As_Already_Paid_By_Default') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.auto_pay_invoices_if_client_has_available_credit" name="check-button" switch>{{ $t('sales_configuration.Auto_Pay_Invoices_if_the_Client_Has_Available_Credit') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.send_invoices_estimates_via_whats_app" name="check-button" switch>{{ $t('sales_configuration.Send_Invoices_Estimates_Via_Whats_App') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.display_invoice_profit" name="check-button" switch>{{ $t('sales_configuration.Display_the_Invoice_Profit') }}</b-form-checkbox>-->
<!--              </div>-->

<!--              <div class="col-lg-12 mb-10">-->
<!--                <b-form-checkbox size="lg" v-model="data.custom_journal_description" name="check-button" switch>{{ $t('sales_configuration.Custom_Journal_Description') }}</b-form-checkbox>-->
<!--              </div>-->

              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="true" v-model="data.is_company_has_tax_no" name="check-button" switch>{{ $t('sales_configuration.is_company_has_tax_no') }}</b-form-checkbox>
              </div>

              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.is_apply_taxes_setting" name="check-button" switch>{{ $t('sales_configuration.is_apply_taxes_setting') }}</b-form-checkbox>
              </div>

              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.is_apply_tax_quotation" name="check-button" switch>{{ $t('sales_configuration.is_apply_tax_quotation') }}</b-form-checkbox>
              </div>

              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.is_apply_tax_debit" name="check-button" switch>{{ $t('sales_configuration.is_apply_tax_debit') }}</b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.convert_delivery_from_pending" name="check-button" switch>{{ $t('sales_configuration.convert_delivery_from_pending') }}</b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-10">
                <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.convert_delivery_without_available" name="check-button" switch>{{ $t('sales_configuration.convert_delivery_without_available_qty') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-10">
                <label>{{ $t('sales_configuration.billing_method') }}</label>
                <select name="" id="billing_method" v-model="data.invoice_method" class="custom-select" :class="validation && validation.invoice_method ? 'is-invalid' : ''">
                  <option v-for="(row, index) in billing_method_list" :value="row.id" :key="'a'+index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.invoice_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.invoice_method[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-10">
                <label>{{ $t('sales_configuration.discount_option') }}</label>
                <select name="" id="discount_option" v-model="data.discount_option" class="custom-select" :class="validation && validation.discount_option ? 'is-invalid' : ''">
                  <option v-for="(row, index) in discount_option_list" :value="row.id" :key="'b'+index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.discount_option" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.discount_option[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-10">
                <label>{{ $t('sales_configuration.zero_fraction_appearing') }}</label>
                <select name="" id="tex_id" v-model="data.price_list_invoices" class="custom-select" :class="validation && validation.zero_fraction_appearing ? 'is-invalid' : ''">
                  <option v-for="(row, index) in zero_fraction_appearing_list" :value="row.id" :key="'c'+index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.price_list_invoices" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.price_list_invoices[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-10">
                <label>{{ $t('sales_configuration.committed_type') }}</label>
                <select name="" id="committed_type" v-model="data.committed_type" class="custom-select">
                  <option v-for="row in commited_method_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.committed_type" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.committed_type[0] }}
                                    </span>
              </div>
<!--              <div class="col-lg-12 mb-10">-->
<!--                <label>{{ $t('sales_configuration.pos_policy') }}</label>-->
<!--                <vue-editor ref="editor" v-model="data.pos_policy"></vue-editor>-->
<!--                <span v-if="validation && validation.pos_policy" class="fv-plugins-message-container invalid-feedback">-->
<!--                                      {{ validation.pos_policy[0] }}-->
<!--                                    </span>-->
<!--              </div>-->


            </div>
          </div>
        </div>
      </div>


      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import {VueEditor} from "vue2-editor";

export default {
  name: "form-sales-configuration",
  components: {
    VueEditor
  },
  data() {
    return {
      mainRoute: 'sales/sales_configuration',
      mainRouteDependency: 'base/dependency',

      data: {
        invoice_method: '',
        discount_option: '',
        committed_type: '',
        price_list_invoices: '',//
        pos_policy: '',//

        enable_changing_invoice_number: false,
        disable_invoice_items_edit: false,
        disable_estimates_module: false,
        enable_invoice_manual_statuses: false,
        enable_estimate_manual_statuses: false,
        disable_shipping_options: false,
        enable_maximum_discount: false,
        mark_invoices_as_already_paid_by_default: false,
        auto_pay_invoices_if_client_has_available_credit: false,
        send_invoices_estimates_via_whats_app: false,
        display_invoice_profit: false,
        custom_journal_description: false,
        is_company_has_tax_no: false,
        is_apply_taxes_setting: false,
        is_apply_tax_quotation: false,
        is_apply_tax_debit: false,
        convert_delivery_from_pending: false,
        convert_delivery_without_available: false,
      },

      billing_method_list: [],
      commited_method_list: [{id: 0, name: this.$t('none')}, {id: 1, name: this.$t('all')}, {id: 2, name: this.$t('many')}],
      discount_option_list: [],
      zero_fraction_appearing_list: [],
      validation: null,
    };
  },
  computed: {
    truFalseKeys() {
      return [
        "enable_changing_invoice_number",
        "disable_invoice_items_edit",
        "disable_estimates_module",
        "enable_invoice_manual_statuses",
        "enable_estimate_manual_statuses",
        "disable_shipping_options",
        "enable_maximum_discount",
        "mark_invoices_as_already_paid_by_default",
        "auto_pay_invoices_if_client_has_available_credit",
        "send_invoices_estimates_via_whats_app",
        "display_invoice_profit",
        "custom_journal_description",
        "is_company_has_tax_no",
        "is_apply_taxes_setting",
        "is_apply_tax_quotation",
        "is_apply_tax_debit",
        "convert_delivery_from_pending",
        "convert_delivery_without_available"
      ];
    },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      //     this.create();
      // }
      this.create();
    },

    create() {
      const trueFalseHandler = this.truFalseKeys.reduce((acc, key) => {
        acc[key] = this.data[key] ? 1 : 0;
        return acc;
      }, {});
      ApiService.post(`${this.mainRoute}`, {
        invoice_method: this.data.invoice_method,
        discount_option: this.data.discount_option,
        committed_type: this.data.committed_type,
        pos_policy: this.data.pos_policy,
        price_list_invoices: this.data.price_list_invoices,
        ...trueFalseHandler,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },


    update() {
      this.data.status = this.data.status ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'sales_configuration.index'});
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}`).then((response) => {
        this.data.invoice_method = response.data.data.invoice_method;
        this.data.discount_option = response.data.data.discount_option;
        this.data.committed_type = response.data.data.committed_type;
        this.data.pos_policy = response.data.data.pos_policy;
        this.data.price_list_invoices = response.data.data.price_list_invoices;
        this.truFalseKeys.forEach((key) => {
          this.data[key] = response.data.data[key] == 1 ? true : false;
        });
      });
    },
    getBillingMethodList() {
      ApiService.get(this.mainRouteDependency + "/billing_method_list").then((response) => {
        this.billing_method_list = response.data.data;
      });
    },
    getDiscountOptionList() {
      ApiService.get(this.mainRouteDependency + "/discount_option_list").then((response) => {
        this.discount_option_list = response.data.data;
      });
    },
    getZeroFractionAppearingList() {
      ApiService.get(this.mainRouteDependency + "/zero_fraction_appearing_list").then((response) => {
        this.zero_fraction_appearing_list = response.data.data;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_configuration_management")}]);
    this.getBillingMethodList();
    this.getDiscountOptionList();
    this.getZeroFractionAppearingList();

    // if (this.idEditing) {
    this.getData();
    // }

  },
};
</script>
